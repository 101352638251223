import NavBar from "./Components/NavBar";
import Router from "./Utils/router";

export interface IAppProps {}

export default function App(props: IAppProps) {
  return (
    <>
      <NavBar />
      <Router />
    </>
  );
}
