import { Route, Switch } from "react-router";
import { ROUTES } from "./constants";

export interface IRouterProps {}

export default function Router(props: IRouterProps) {
  return (
    <Switch>
      {ROUTES.map((route) => (
        <Route exact path={route.url} component={route.component} />
      ))}
    </Switch>
  );
}
