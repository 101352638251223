import { Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";
import React from "react";
import Faq from "../Views/Faq";
import Home from "../Views/Home";
import Roulette from "../Views/Roulette";
import ContactUs from "../Views/ContactUs";
import { Question } from "./types";

export const HEADER_HEIGHT = 138;
export const HEADER_MARGIN = (theme: Theme): CSSProperties => ({
  paddingTop: HEADER_HEIGHT,
  [theme.breakpoints.down("xs")]: {
    paddingTop: 280,
  },
});

export const FAQ_QUESTIONS: Array<Question> = [
  { title: "What is this website?", body: "We are a site that provides xyz" },
  {
    title: "How do I use the site?",
    body: "You can use our gambling method by going to the roulette page.",
  },
  {
    title: "Do you charge anything?",
    body: "No we do not charge for this service however donations are appreciated.",
  },
];
export const ROUTES: Array<{
  url: string;
  name: string;
  component?: (props?: any) => React.ReactElement;
}> = [
  { url: "/", name: "HOME", component: Home },
  { url: "/roulette", name: "ROULETTE", component: Roulette },
  { url: "/faq", name: "FAQ", component: Faq },
  { url: "/contact-us", name: "CONTACT US", component: ContactUs },
];
