import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { HEADER_MARGIN } from "../Utils/constants";
//import Vegas from "../Images/vegas.jpg";
import VegasMobile from "../Images/vegas_mobile.png";
import VegasVideo from "../Videos/vegas_street_view.mp4";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  rootPadding: { ...HEADER_MARGIN(theme) },
  root: {
    minHeight: "100vh",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${VegasMobile})`,
      backgroundSize: "cover",
    },
  },
  text: {
    color: "white",
    width: 600,
    textAlign: "center",
    "text-shadow":
      "-1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  item: {
    width: "800px",
    marginTop: 25,
    marginBottom: 0,
    backgroundColor: "rgba(0,0,0,0.0)",
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.6)",
    },
  },
  videoBG: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    zIndex: -1,
    objectFit: "fill",
    [theme.breakpoints.down("xs")]: { display: "none" },
  },
  callToAction: {
    letterSpacing: "2px",
    marginTop: 50,
  },
  bottomItem: { marginBottom: 100 },
}));

export interface IHomeProps {}

export default function Home(props: IHomeProps) {
  const classes = useStyles();
  return (
    <>
      <video autoPlay muted loop className={classes.videoBG}>
        <source src={VegasVideo} />
      </video>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.root + " " + classes.rootPadding}
      >
        <Grid item className={classes.item}>
          <Typography variant="h4" className={classes.text}>
            Welcome to Beginners' Luck
          </Typography>
        </Grid>
        <Grid item className={classes.item}>
          <Typography variant="h6" className={classes.text}>
            Don't you wish you could go back in time and experience Beginner's
            Luck again? <br />
            <br />
            Well now you can. With our gambling system you will be on a hot
            streak in no time!
          </Typography>
        </Grid>
        <Grid item className={classes.bottomItem}>
          <Button
            variant="contained"
            color="primary"
            className={classes.callToAction}
            component={Link}
            to="/roulette"
          >
            Let's Win
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
