import { CheckResult, MatchFunction, Rolls } from "./types";

const MIN_CONSECUTIVE = 3;
const WHEEL = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
];

export const getNextBet = (rolls: Rolls): Array<CheckResult> => {
  let results = [
    checkColors(rolls),
    checkMath(rolls),
    checkThirds(rolls),
    checkHalf(rolls),
  ];
  results = results.filter((x) => {
    return x.strength > 0;
  });
  results.sort((a, b) => (a.strength > b.strength ? -1 : 1));
  return results;
};

const checkColors = (rolls: Rolls): CheckResult => {
  const checkRed = checkPattern(rolls, isRed);
  const checkBlack = checkPattern(rolls, isBlack);
  if (checkBlack !== -1 || checkRed !== -1)
    return checkBlack > checkRed
      ? { type: "color", result: "black", strength: checkBlack }
      : { type: "color", result: "red", strength: checkRed };
  else return { type: "color", result: "", strength: -1 };
};

const checkMath = (rolls: Rolls): CheckResult => {
  const checkOdd = checkPattern(rolls, isOdd);
  const checkEven = checkPattern(rolls, isEven);
  if (checkOdd !== -1 || checkEven !== -1)
    return checkOdd > checkEven
      ? { type: "math", result: "odd", strength: checkOdd }
      : { type: "math", result: "even", strength: checkEven };
  else return { type: "math", result: "", strength: -1 };
};

const checkHalf = (rolls: Rolls): CheckResult => {
  const checkTop = checkPattern(rolls, isTopHalf);
  const checkBottom = checkPattern(rolls, isBottomHalf);
  if (checkBottom !== -1 && checkTop !== -1)
    return checkTop > checkBottom
      ? { type: "half", result: "top", strength: checkTop }
      : { type: "half", result: "bottom", strength: checkBottom };
  else return { type: "half", result: "", strength: -1 };
};

const checkThirds = (rolls: Rolls): CheckResult => {
  const resultArray = [
    { k: "upper", v: checkPattern(rolls, isUpperThird) },
    { k: "middle", v: checkPattern(rolls, isMiddleThird) },
    { k: "lower", v: checkPattern(rolls, isLowerThird) },
  ].sort(function (a, b) {
    return b.v - a.v;
  });
  const highestKey = resultArray[0].k;
  const highestValue = resultArray[0].v;
  switch (highestKey) {
    case "upper":
      return { type: "thirds", result: "upper", strength: highestValue };
    case "middle":
      return { type: "thirds", result: "middle", strength: highestValue };
    case "lower":
      return { type: "thirds", result: "lower", strength: highestValue };
    default:
      return { type: "thirds", result: "", strength: -1 };
  }
};

const checkPattern = (rolls: Rolls, matchesPattern: MatchFunction): number => {
  let matchStreak = 0;
  for (let i = 0; i < rolls.length; i++) {
    if (matchesPattern(rolls[i]) && !zerosCheck(rolls[i])) matchStreak++;
    else break;
  }
  if (matchStreak >= MIN_CONSECUTIVE) return matchStreak;
  else return -1;
};

const zerosCheck = (x: string) => {
  return x === "0" || x === "00";
};

const isEven = (x: string) => parseInt(x) % 2 === 0;
const isOdd = (x: string) => parseInt(x) % 2 === 1;
const isUpperThird = (x: string) => parseInt(x) > 24;
const isMiddleThird = (x: string) => parseInt(x) <= 24 && parseInt(x) > 12;
const isLowerThird = (x: string) => parseInt(x) <= 12;
const isTopHalf = (x: string) => parseInt(x) > 18;
const isBottomHalf = (x: string) => parseInt(x) <= 18;
export const isRed = (x: string) =>
  [
    "1",
    "3",
    "5",
    "7",
    "9",
    "12",
    "14",
    "16",
    "18",
    "19",
    "21",
    "23",
    "25",
    "27",
    "30",
    "32",
    "34",
    "36",
  ].includes(x);
export const isBlack = (x: string) =>
  [
    "2",
    "4",
    "6",
    "8",
    "10",
    "11",
    "13",
    "15",
    "17",
    "20",
    "22",
    "24",
    "26",
    "28",
    "29",
    "31",
    "33",
    "35",
  ].includes(x);

const filterSwitch = (result: CheckResult, wheel: Rolls): Rolls => {
  switch (result.type) {
    case "color":
      return filterColor(result.result, wheel);
    case "math":
      return filterMath(result.result, wheel);
    case "thirds":
      return filterThirds(result.result, wheel);
    case "half":
      return filterHalf(result.result, wheel);
    default:
      return [""];
  }
};

const filterColor = (val: string, wheel: Rolls) => {
  let newWheel: Rolls = [];
  switch (val) {
    case "red":
      newWheel = wheel.filter(isBlack);
      break;
    case "black":
      newWheel = wheel.filter(isRed);
      break;
    default:
      break;
  }
  return newWheel === [] ? wheel : newWheel;
};

const filterMath = (val: string, wheel: Rolls) => {
  let newWheel: Rolls = [];
  switch (val) {
    case "odd":
      newWheel = wheel.filter(isEven);
      break;
    case "even":
      newWheel = wheel.filter(isOdd);
      break;
    default:
      break;
  }
  return newWheel === [] ? wheel : newWheel;
};

const filterHalf = (val: string, wheel: Rolls) => {
  let newWheel: Rolls = [];
  switch (val) {
    case "top":
      newWheel = wheel.filter(isBottomHalf);
      break;
    case "bottom":
      newWheel = wheel.filter(isTopHalf);
      break;
    default:
      break;
  }
  return newWheel === [] ? wheel : newWheel;
};
const filterThirds = (val: string, wheel: Rolls) => {
  let newWheel: Rolls = [];
  switch (val) {
    case "upper":
      newWheel = wheel.filter(isLowerThird).concat(wheel.filter(isMiddleThird));
      break;
    case "middle":
      newWheel = wheel.filter(isUpperThird).concat(wheel.filter(isLowerThird));
      break;
    case "lower":
      newWheel = wheel.filter(isUpperThird).concat(wheel.filter(isMiddleThird));
      break;
    default:
      break;
  }
  return newWheel === [] ? wheel : newWheel;
};

export const getNumberBet = (results: Array<CheckResult>): string => {
  let possibleRolls = WHEEL;
  for (let i = 0; i < results.length; i++) {
    if (results[i].strength === -1) break;
    let newPossibleRolls = filterSwitch(results[i], possibleRolls);
    if (newPossibleRolls.length !== 0) possibleRolls = newPossibleRolls;
  }
  console.log(possibleRolls);
  return possibleRolls[Math.floor(Math.random() * (possibleRolls.length - 1))];
};

const UPPER_HALF = "Lower Half";
const LOWER_HALF = "Upper Half";
const RED = "Red";
const BLACK = "Black";
const ODD = "Odd";
const EVEN = "Even";
const UPPER_THIRD = "Third Dozen (25-36)";
const UPPER_SECOND = "Second Dozen (13-24)";
const UPPER_FIRST = "First Dozen (1-12)";

export const getGroupBet = (result: CheckResult): string => {
  switch (result.type) {
    case "color":
      return result.result === "black" ? RED : BLACK;
    case "math":
      return result.result === "even" ? ODD : EVEN;
    case "thirds":
      switch (result.result) {
        case "upper":
          return UPPER_SECOND + " and " + UPPER_FIRST;
        case "middle":
          return UPPER_THIRD + " and " + UPPER_FIRST;
        case "lower":
          return UPPER_THIRD + " and " + UPPER_SECOND;
        default:
          return "";
      }
    case "half":
      return result.result === "bottom" ? UPPER_HALF : LOWER_HALF;
    default:
      return "";
  }
};

export const getNumberGroups = (x: string) => {
  const groupArray = [];
  groupArray.push(isRed(x) ? RED : BLACK);
  groupArray.push(isEven(x) ? EVEN : ODD);
  groupArray.push(isBottomHalf(x) ? LOWER_HALF : UPPER_HALF);
  if (isUpperThird(x)) groupArray.push(UPPER_THIRD);
  if (isMiddleThird(x)) groupArray.push(UPPER_SECOND);
  if (isLowerThird(x)) groupArray.push(UPPER_FIRST);
  return groupArray;
};
