import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  bodyText: {
    padding: 25,
    paddingBottom: 50,
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
  headerText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,
      textAlign: "center",
    },
  },
}));

export interface IWinDialogProps {
  open: boolean;
  onClose: () => void;
  winValue: number;
}

export default function WinDialog(props: IWinDialogProps) {
  const classes = useStyles();
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        <Typography variant="h4" className={classes.headerText}>
          Congratulations!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" className={classes.bodyText} align="center">
          You just won ${props.winValue}!<br />
          <br /> If you appreciate the extra cash feel free to{" "}
          <a
            href="https://www.buymeacoffee.com/tibblist"
            target="_blank"
            rel="noreferrer noopener"
          >
            Buy me a Beer
          </a>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" size="large" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
