import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { checkForWin, isNumber, parseNextBet } from "../Utils/funcs";
import { getNextBet, isBlack } from "../Utils/prediction";
import { CheckResult, TableInfo } from "../Utils/types";
import WinDialog from "./WinDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "max-content",
    margin: 40,
    padding: 25,
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  tableBody: {
    //width: 200,
    margin: "auto",
    border: "2px solid black",
    padding: 5,
  },
  tableCell: {},
  textField: { marginRight: 15 },
  inputBody: {
    marginBottom: 25,
  },
  headerText: {
    margin: "auto",
    marginBottom: 10,
    marginTop: 10,
    maxWidth: 300,
  },
  item: { marginTop: 10, marginBottom: 10 },
}));

export interface ITableProps {}

export default function Table(props: ITableProps & TableInfo) {
  const minBet = props.initialBet || props.minBet;

  const [nextRoll, setNextRoll] = React.useState("");
  const [nextBet, setNextBet] = React.useState([] as Array<CheckResult>);
  const [pastRolls, setPastRolls] = React.useState([] as Array<string>);
  const [nextBetAmount, setNextBetAmount] = React.useState(minBet);
  const [displayBet, setDisplayBet] =
    React.useState<React.ReactElement | string>("");
  const [winValue, setWinValue] = React.useState(0);
  const [multiple, setMultiple] = React.useState(false);
  const [group, setGroup] = React.useState(true);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    setNextBet(getNextBet(pastRolls));
  }, [pastRolls]);

  React.useEffect(() => {
    setDisplayBet(parseNextBet(nextBet, group, multiple));
  }, [nextBet, multiple, group]);

  const changeRoll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") setNextRoll("");
    if (
      isNumber(event.target.value) &&
      parseInt(event.target.value) >= 0 &&
      parseInt(event.target.value) <= 36
    )
      setNextRoll(event.target.value);
  };

  const triggerWin = (winValue: number) => {
    setDialogOpen(true);
    setWinValue(winValue);
  };

  const submitRoll = () => {
    if (nextRoll === "") return;
    if (checkForWin(nextBet, nextRoll, group, multiple)) {
      setNextBetAmount(minBet);
      if (!multiple && group) triggerWin(minBet);
    } else {
      console.log("No Win");
      if (nextBet.length !== 0)
        setNextBetAmount(
          nextBetAmount * 2 < props.maxBet ? nextBetAmount * 2 : minBet
        );
    }
    setPastRolls([nextRoll].concat(pastRolls));
    setNextRoll("");
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };
  return (
    <>
      <WinDialog open={dialogOpen} onClose={closeDialog} winValue={winValue} />
      <Paper className={classes.root}>
        <Grid container direction="column" justify="center">
          <Grid item className={classes.item}>
            <Typography variant="h4" align="center">
              {props.nickname}
            </Typography>
            <Typography variant="body1" align="center">
              Minimum Bet: {props.minBet}
            </Typography>
            <Typography variant="body1" align="center">
              Maximum Bet: {props.maxBet}
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={multiple}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setMultiple(event.target.checked);
                  }}
                  name="checkedA"
                />
              }
              label="Multiple positions*"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!group}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setGroup(!event.target.checked);
                  }}
                  name="checkedA"
                />
              }
              label="Bet on numbers*"
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              align="center"
              className={classes.headerText}
            >
              {nextBet.length === 0 ? (
                "Not enough information to determine a bet at this time"
              ) : (
                <>
                  Your next bet should be:
                  <br /> {displayBet}
                </>
              )}
            </Typography>
            <Typography
              variant="h6"
              align="center"
              className={classes.headerText}
            >
              {nextBet.length === 0 ? (
                ""
              ) : (
                <>Your next bet amount should be: {nextBetAmount}</>
              )}
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.inputBody}
          >
            <TextField
              label="Roll"
              placeholder="Enter new roll here"
              onChange={changeRoll}
              value={nextRoll}
              variant="outlined"
              className={classes.textField}
              fullWidth
            />
            <Grid item style={{ marginTop: 15 }}>
              <Button onClick={submitRoll} variant="outlined">
                Submit
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justify="center"
            className={classes.tableBody}
          >
            <Grid item>
              <Typography align="center">Past Rolls:</Typography>
            </Grid>
            {pastRolls.map((roll) => {
              return (
                <Grid
                  item
                  className={classes.tableCell}
                  style={{
                    marginTop: -2,
                    color: isBlack(roll) ? "black" : "red",
                  }}
                >
                  <Typography align={isBlack(roll) ? "right" : "left"}>
                    {roll}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
          <Grid item style={{ marginTop: 20 }}>
            <Typography variant="caption">
              * These options are provided for fun bets but do not fully utilize
              our system
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
