import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgb(161, 127, 26)",
    },
    secondary: {
      main: "#FF2400",
    },
  },
});
