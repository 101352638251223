import { CheckResult } from "./types";
import { getNumberBet, getGroupBet, getNumberGroups } from "./prediction";
import { Typography } from "@material-ui/core";
export const isNumber = (x: string) => {
  const newValue = parseInt(x);
  return !(isNaN(newValue) && x !== "");
};

export const parseNextBet = (
  results: Array<CheckResult>,
  group: boolean,
  multiple: boolean
): string | React.ReactElement => {
  if (results.length === 0) return "";
  if (group && multiple) {
    return (
      <ul style={{ marginTop: 0 }}>
        {results.map((result) => {
          return (
            <li>
              <Typography variant="body1" align="left">
                {getGroupBet(result)}
              </Typography>
            </li>
          );
        })}
      </ul>
    );
  } else if (group) {
    return getGroupBet(results[0]);
  } else {
    return getNumberBet(results);
  }
};

export const checkForWin = (
  nextBet: Array<CheckResult>,
  nextRoll: string,
  group: boolean,
  multiple: boolean
): boolean => {
  if (nextBet.length === 0) return false;
  if (group && multiple) {
    let matchFound = false;
    nextBet.forEach((bet) => {
      if (!matchFound) matchFound = checkForMatch(bet, nextRoll);
    });
    return matchFound;
  } else if (group) {
    return checkForMatch(nextBet[0], nextRoll);
  } else {
    return getNumberBet(nextBet) === nextRoll;
  }
};

const checkForMatch = (result: CheckResult, nextRoll: string): boolean => {
  let matchFound = false;
  const groups = getNumberGroups(nextRoll);
  const matchString = getGroupBet(result).split(" and ");
  matchString.forEach((match) => {
    if (groups.includes(match)) matchFound = true;
  });
  return matchFound;
};
