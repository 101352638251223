import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { FAQ_QUESTIONS, HEADER_MARGIN } from "../Utils/constants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FAQPicture from "../Images/faq.jpeg";
const useStyles = makeStyles((theme) => ({
  root: {
    ...HEADER_MARGIN(theme),
    minHeight: "100vh",
    minWidth: "100vw",
    backgroundColor: "black",
    backgroundRepeat: "no-repeat",
    paddingBottom: 200,
  },
  questionRoot: { width: "80%", margin: "auto", maxWidth: 800, marginTop: 25 },
  img: {
    width: 300,
    height: 300,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 400,
    },
  },
}));

export interface IFaqProps {}

export default function Faq(props: IFaqProps) {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      //justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        <img
          src={`${FAQPicture}`}
          alt="question mark"
          className={classes.img}
        ></img>
      </Grid>
      <Grid item className={classes.questionRoot}>
        {FAQ_QUESTIONS.map((question, idx) => {
          return (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"panel" + idx + "-content"}
                id={"panel" + idx + "-header"}
              >
                {question.title}
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{question.body}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Grid>
    </Grid>
  );
}
