import {
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import Table from "../Components/Table";
import { isNumber } from "../Utils/funcs";
import { TableInfo } from "../Utils/types";
import GreenFelt from "../Images/green-felt-large.jpg";
import GreenFeltMobile from "../Images/green-felt-mobile.png";
import { HEADER_MARGIN } from "../Utils/constants";

const useStyles = makeStyles((theme) => ({
  //textfield: { width: 400, margin: "auto" },
  root: {
    backgroundImage: `url(${GreenFelt})`,
    minHeight: "100vh",
    backgroundSize: "cover",
    ...HEADER_MARGIN(theme),
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${GreenFeltMobile})`,
      backgroundRepeat: "repeat",
      height: "auto",
      backgroundSize: "auto",
      minHeight: "100vh",
    },
  },
  item: {
    margin: "auto",
    marginTop: 10,
    width: 300,
    [theme.breakpoints.down("sm")]: { width: 225 },
  },
  paperRoot: {
    width: 400,
    padding: 25,
    margin: "auto",
    marginTop: 50,
    [theme.breakpoints.down("sm")]: { width: 300 },
  },
}));

export interface IRouletteProps {}

export default function Roulette(props: IRouletteProps) {
  const [minBet, setMinBet] = useState("");
  const [maxBet, setMaxBet] = useState("");
  const [initialBet, setInitialBet] = useState("");
  const [nickname, setNickname] = useState("");
  const [tables, setTables] = useState([] as Array<TableInfo>);
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justify="center"
      className={classes.root}
    >
      <Paper className={classes.paperRoot}>
        <Grid container direction="column" justify="center">
          <Grid item>
            <Typography variant="h6" align="center">
              Create a New Table
            </Typography>
          </Grid>
          <Grid item className={classes.item}>
            <TextField
              label="Table Nickname"
              placeholder="Set a table nickname to refer back to it"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNickname(event.target.value);
              }}
              value={nickname}
              fullWidth
            />
          </Grid>
          <Grid item className={classes.item}>
            <TextField
              label="Table Minimum Bet"
              placeholder="Enter the minimum bet at the table"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (isNumber(event.target.value)) setMinBet(event.target.value);
              }}
              value={minBet}
              fullWidth
            />
          </Grid>
          <Grid item className={classes.item}>
            <TextField
              label="Table Maximum Bet"
              placeholder="Enter the maximum bet at the table"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (isNumber(event.target.value)) setMaxBet(event.target.value);
              }}
              value={maxBet}
              fullWidth
            />
          </Grid>
          <Grid item className={classes.item}>
            <TextField
              label="Preferred Initial Bet"
              placeholder="Optional set your own initial bet"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (isNumber(event.target.value))
                  setInitialBet(event.target.value);
              }}
              value={initialBet}
              fullWidth
            />
          </Grid>
          <Grid
            item
            className={classes.item}
            style={{ width: "max-content", marginTop: 25 }}
          >
            <Button
              onClick={() => {
                const newTables = Array.from(tables);
                newTables.push({
                  minBet: parseInt(minBet),
                  maxBet: parseInt(maxBet),
                  initialBet:
                    initialBet === "" ? undefined : parseInt(initialBet),
                  nickname,
                });
                setTables(newTables);
              }}
              variant="contained"
              color="primary"
            >
              CREATE
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Grid container direction="row" justify="center">
        {tables.map((table) => {
          return <Table {...table} />;
        })}
      </Grid>
    </Grid>
  );
}
