import {
  Button,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LuckyLogo from "../Images/luck.jpg";
import { HEADER_HEIGHT, ROUTES } from "../Utils/constants";
import MenuIcon from "@material-ui/icons/Menu";
const MIN_HEIGHT = HEADER_HEIGHT;

const useStyles = makeStyles((theme) => ({
  showMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "inherit",
    },
  },
  hideMobile: {
    //display: "inherit",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  root: {
    minHeight: MIN_HEIGHT,
    backgroundColor: "transparent",
    position: "absolute",
    width: "100%",
  },
  subRoot: { minHeight: MIN_HEIGHT },
  image: {
    width: 200,
    height: 128,
    marginBottom: -6,
    marginLeft: 10,
    marginTop: 10,
    borderRadius: 10,
    [theme.breakpoints.down("xs")]: {
      width: 150,
      height: 96,
      margin: "auto",
      display: "block",
      marginTop: 25,
    },
  },
  imageRoot: {
    [theme.breakpoints.down("xs")]: { width: "100vw" },
  },
  buttonItem: {
    [theme.breakpoints.down("xs")]: { width: "100vw", marginTop: 5 },
  },
  button: {
    color: "white",
    margin: "auto",
    display: "block",
    width: "max-content",
    textAlign: "center",
    //backgroundColor: "rgba(0,0,0,0.3)",
    "text-shadow":
      "-1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 5,
      marginRight: 5,
      marginTop: 10,
    },
  },
  activeButton: { color: theme.palette.secondary.main },
  collapseRoot: { [theme.breakpoints.up("sm")]: { display: "none" } },
  iconButton: {
    position: "absolute",
    top: 10,
    right: 10,
    color: "white",
    display: "none",
    [theme.breakpoints.down("xs")]: { display: "inherit" },
  },
  menuIcon: {
    width: 48,
    height: 48,
  },
}));
export interface INavBarProps {}

export default function NavBar(props: INavBarProps) {
  const [menuHidden, setMenuHidden] = useState(
    window.innerWidth < 768 ? true : false
  );
  const classes = useStyles();
  const location = useLocation();
  console.log(location);

  const buttonClick = () => {
    setMenuHidden(true);
  };
  const MenuItems = (
    <>
      {ROUTES.map((route) => {
        const isActive = location.pathname === route.url;
        return (
          <Grid item className={classes.buttonItem}>
            <Button
              component={Link}
              to={route.url}
              size="large"
              className={
                (isActive ? classes.activeButton : "") + " " + classes.button
              }
              onClick={buttonClick}
            >
              {route.name}
            </Button>
          </Grid>
        );
      })}
    </>
  );

  return (
    <>
      <Paper elevation={0} square className={classes.root}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          className={classes.subRoot}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ width: "max-content" }}
          >
            <Grid item className={classes.imageRoot}>
              <Link to="/">
                <img
                  src={LuckyLogo}
                  alt="Luck Logo"
                  className={classes.image}
                ></img>
              </Link>
            </Grid>
          </Grid>
          <Collapse
            in={!menuHidden}
            collapsedHeight={0}
            className={classes.collapseRoot}
          >
            {MenuItems}
          </Collapse>
          <Grid
            container
            direction="row"
            style={{ width: "max-content" }}
            className={classes.hideMobile}
          >
            {MenuItems}
          </Grid>
        </Grid>
      </Paper>
      <IconButton
        className={classes.iconButton}
        onClick={() => {
          setMenuHidden(!menuHidden);
        }}
      >
        <MenuIcon className={classes.menuIcon} />
      </IconButton>
    </>
  );
}
