import { makeStyles } from "@material-ui/core";
import { HEADER_MARGIN } from "../Utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    ...HEADER_MARGIN(theme),
  },
}));

export interface IContactUsProps {}

export default function ContactUs(props: IContactUsProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <form
        action="https://formsubmit.co/41145bb6104fe2f50c8d950200501ab5"
        method="POST"
      >
        <input type="text" name="Name" required />
        <input type="email" name="Email" required />
        <input type="text" name="Body" required />
        <button type="submit">Send</button>
      </form>
    </div>
  );
}
